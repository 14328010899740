import React, { createContext, ReactNode, useEffect } from "react";
import useStore from "../../hooks/useStore";
import { Store } from "../../types/StoreType";
import { useOrderStatus } from "../../hooks/useSharedContext";

const defaultProviderValue: Store | undefined = undefined;

const StoreContext = createContext(defaultProviderValue as Store | undefined);

const StoreProvider = ({ children }: { children: ReactNode }) => {
  const { loginSuccessful } = useOrderStatus();
  const { store, refreshStore } = useStore(loginSuccessful);

  useEffect(() => {
    if (loginSuccessful) {
      refreshStore();
    }
  }, [loginSuccessful, refreshStore]);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
