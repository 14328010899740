import React, { createContext, ReactNode, useEffect, useMemo } from "react";
import {
  loopNotificationSound,
  stopNotificationSound,
  playConsolidationAlert,
} from "../../services/sounds";
import {
  useStateDefinitions,
  useWaitTime,
  useNetworkStatus,
} from "../../hooks/useSharedContext";
import usePollOrderStatus from "../../hooks/usePollOrderStatus";
import useIsOnline from "../../hooks/useIsOnline";
import { OrderDetails } from "../../types/OrderDetailsType";
import useRealtimeUpdates from "../../hooks/useRealtimeUpdates";
import {
  createOrderGroupMapping,
  OrderGroupMapping,
} from "../../services/orderGrouping";
import * as persistenceManager from "../../services/persistenceManager";

interface OrderStatusContextValue {
  historyOrders: OrderDetails[];
  refreshOrderDetails: () => void;
  loginSuccessful: boolean;
  // Current orders is all orders minus those have order.status === "Completed" || (order.isAcknowledged === true && order.status !== "Arrived")
  currentOrders: OrderDetails[];
  pollOrdersError: undefined | Error;
  loadingFirstRequest: boolean;
  onClearOrders: () => void;
  resetLoadingFirstRequest: () => void;
  useRealtimeOrders: boolean;
  // orderGroups will be undefined in orderGrouping feature is not enabled
  orderGroups: OrderGroupMapping | undefined;
}

const defaultProviderValue: OrderStatusContextValue = {
  historyOrders: [],
  refreshOrderDetails: () =>
    console.log("Placeholder function for refreshOrderDetails"),
  loginSuccessful: false,
  currentOrders: [],
  pollOrdersError: undefined,
  loadingFirstRequest: true,
  onClearOrders: () => {
    console.log("Placeholder function for onClearOrders");
  },
  resetLoadingFirstRequest: () => {
    console.log("Placeholder function for resetLoadingFirstRequest");
  },
  useRealtimeOrders: false,
  orderGroups: undefined,
};

const OrderStatusContext = createContext(defaultProviderValue);

const OrderStatusProvider = ({ children }: { children: ReactNode }) => {
  const {
    orders,
    pollOrdersError,
    refreshOrderDetails,
    loginSuccessful,
    loadingFirstRequest,
    clearOrders,
    ordersStateHash,
    resetLoadingFirstRequest,
    setDisablePolling,
    lastOrderPollTimestamp,
  } = usePollOrderStatus();

  const { refreshWaitTime, setWaitTimePollingDisabled } = useWaitTime();

  const {
    stateDefinitions,
    stateDefinitionHash,
    stateDefinitionsLoaded,
    updateStateDefinitions,
  } = useStateDefinitions();

  const {
    realtimeOrders,
    setShouldConnectToRealtimeService,
    useRealtimeOrders,
    lastRealtimeEventTime,
  } = useRealtimeUpdates(
    refreshWaitTime,
    stateDefinitionHash,
    refreshOrderDetails
  );

  const { setRealtimeEnabled } = useNetworkStatus();

  useEffect(() => {
    if (useRealtimeOrders) {
      setDisablePolling(true);
      setWaitTimePollingDisabled(true);
    } else {
      setDisablePolling(false);
      setWaitTimePollingDisabled(false);
    }
  }, [useRealtimeOrders, setDisablePolling, setWaitTimePollingDisabled]);

  const filteredOrders: {
    currentOrders: OrderDetails[];
    historyOrders: OrderDetails[];
  } = useMemo(() => {
    const filteredOrdersObject: {
      currentOrders: OrderDetails[];
      historyOrders: OrderDetails[];
    } = {
      currentOrders: [],
      historyOrders: [],
    };

    if (!stateDefinitionsLoaded || !stateDefinitions?.states) {
      return filteredOrdersObject;
    }

    /*
    We need to check the last event time from both sources as if connection is lost and
    websocket is closed we still want to use those orders if they are more recent the the polled orders
 */
    const targetOrders =
      useRealtimeOrders || lastRealtimeEventTime > lastOrderPollTimestamp
        ? realtimeOrders
        : orders;

    for (const order of targetOrders) {
      const targetState = stateDefinitions?.states.find(
        (state) => state.name === order.status
      );
      if (!targetState) {
        console.log("Unable to identify target state for order", order);
      } else if (targetState.isActive) {
        filteredOrdersObject.currentOrders.push(order);
      } else if (targetState.canAcknowledge && !order.isAcknowledged) {
        filteredOrdersObject.currentOrders.push(order);
      } else {
        filteredOrdersObject.historyOrders.push(order);
      }
    }

    return filteredOrdersObject;
  }, [
    orders,
    stateDefinitions,
    stateDefinitionsLoaded,
    realtimeOrders,
    useRealtimeOrders,
    lastRealtimeEventTime,
    lastOrderPollTimestamp,
  ]);

  const orderGroups = useMemo(() => {
    if (
      !stateDefinitionsLoaded ||
      !stateDefinitions?.states ||
      !stateDefinitions.features?.orderGrouping?.enabled
    ) {
      return undefined;
    }

    const groupPrefix = stateDefinitions.features.orderGrouping.groupPrefix;
    const targetOrders =
      useRealtimeOrders || lastRealtimeEventTime > lastOrderPollTimestamp
        ? realtimeOrders
        : orders;
    return createOrderGroupMapping(targetOrders, groupPrefix);
  }, [
    orders,
    realtimeOrders,
    useRealtimeOrders,
    stateDefinitions,
    stateDefinitionsLoaded,
    lastOrderPollTimestamp,
    lastRealtimeEventTime,
  ]);

  const isOnline = useIsOnline();

  useEffect(() => {
    if (loginSuccessful) {
      updateStateDefinitions();
    }
  }, [loginSuccessful, updateStateDefinitions]);

  useEffect(() => {
    if (!loginSuccessful) {
      return;
    }
    if (
      ordersStateHash &&
      stateDefinitionHash &&
      ordersStateHash !== stateDefinitionHash
    ) {
      updateStateDefinitions();
    }
    if (stateDefinitionsLoaded) {
      if (stateDefinitions?.features?.realtimeUpdates) {
        setShouldConnectToRealtimeService(true);
        setRealtimeEnabled(true);
      } else {
        setShouldConnectToRealtimeService(false);
        setRealtimeEnabled(false);
      }
    }
  }, [
    ordersStateHash,
    stateDefinitionHash,
    stateDefinitionsLoaded,
    loginSuccessful,
    updateStateDefinitions,
    setShouldConnectToRealtimeService,
    stateDefinitions,
    setRealtimeEnabled,
  ]);

  // This plays a sound when a new order has arrived
  useEffect(() => {
    if (isOnline && stateDefinitions) {
      const consolidationAlertStates = stateDefinitions.states.filter((state) =>
        state.notificationTypes?.includes("consolidation")
      );
      const consolidationAlertStateNames = consolidationAlertStates.map(
        (state) => state.name
      );
      const unacknowledgedConsolidationAlertOrders = filteredOrders.currentOrders.filter(
        (order) =>
          consolidationAlertStateNames.includes(order.status) &&
          !order.isAcknowledged &&
          order.hasNotified?.includes("consolidation")
      );
      if (unacknowledgedConsolidationAlertOrders.length) {
        const previouslyAlertedOrders = persistenceManager.getAlertedConsolidationOrders();
        const unalertedOrders = unacknowledgedConsolidationAlertOrders
          .filter((order) => !previouslyAlertedOrders.includes(order.helloId))
          .map((order) => order.helloId);
        if (unalertedOrders.length) {
          playConsolidationAlert();
          const newAlertedOrders = [
            ...previouslyAlertedOrders,
            ...unalertedOrders,
          ];
          persistenceManager.setAlertedConsolidationOrders(newAlertedOrders);
          return;
        }
      }
      const notificationAlertStates = stateDefinitions.states.filter(
        (state) =>
          (state.displayState?.alertTimers &&
            state.displayState.alertTimers.length) ||
          state.name === "Arrived"
      );
      const notificationAlertStateNames = notificationAlertStates.map(
        (state) => state.name
      );
      const unacknowledgedNotificationAlertOrders = filteredOrders.currentOrders.filter(
        (order) =>
          notificationAlertStateNames.includes(order.status) &&
          !order.isAcknowledged
      );
      if (unacknowledgedNotificationAlertOrders.length) {
        loopNotificationSound();
      } else {
        stopNotificationSound();
      }
    } else {
      stopNotificationSound();
    }
  }, [filteredOrders, isOnline, stateDefinitions]);

  const providerValue = {
    historyOrders: filteredOrders.historyOrders,
    refreshOrderDetails,
    pollOrdersError,
    loginSuccessful,
    currentOrders: filteredOrders.currentOrders,
    loadingFirstRequest,
    onClearOrders: clearOrders,
    resetLoadingFirstRequest,
    useRealtimeOrders,
    orderGroups,
  };

  return (
    <OrderStatusContext.Provider value={providerValue}>
      {children}
    </OrderStatusContext.Provider>
  );
};

export { OrderStatusContext, OrderStatusProvider };
