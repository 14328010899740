import { useState, useEffect } from "react";
import * as persistenceManager from "../services/persistenceManager";
import { getWebsocketUrl } from "../services/api";
import * as api from "../services/api";
import { Region } from "../types/RegionType";
import { OrderDetails } from "../types/OrderDetailsType";
import {
  StateUpdateReceivedMessage,
  UpdateOrderMessage,
  UpdateStateMessage,
} from "../types/WebsocketTypes";
import { useNetworkStatus } from "./useSharedContext";

const CONNECTION_CHECK_SEND_TIMER = 1000 * 20;
const CONNECTION_CHECK_RECEIVE_TIMER = 1000 * 25;
const RECONNECTION_ATTEMPT_MINIMUM_TIME = 1000 * 10;
const RESYNC_POLL_INTERVAL = 1000 * 120;
const FALLBACK_MAXIMUM_DISCONNECTION_PERIOD = 1000 * 60;

export default (
  refreshWaitTime: () => void,
  stateHash: string | undefined,
  refreshOrderDetails: () => Promise<void>
) => {
  const [isConnected, setIsConnected] = useState(false);
  const [websocket, setWebsocket] = useState<WebSocket | undefined>();
  const [shouldConnect, setShouldConnect] = useState(false);
  const [lastEventSendTime, setLastEventSendTime] = useState("");
  const [lastReceivedTime, setLastReceivedTime] = useState("");
  const [orders, setOrders] = useState<OrderDetails[] | undefined>();
  const [useRealtimeOrders, setUseRealtimeOrders] = useState(false);
  const [shouldCheckReceivedTime, setShouldCheckReceivedTime] = useState(false);
  const { setRealtimeConnected, lastOrderPollSuccess } = useNetworkStatus();
  const [lastConnectionAttempt, setLastConnectionAttempt] = useState<
    Date | undefined
  >();
  const [triggerReconnect, setTriggerReconnect] = useState(false);
  // shouldResync indicates that realtime is reconnected after a connection loss but the latest order state was not able to be retrieved
  const [shouldResync, setShouldResync] = useState(false);

  useEffect(() => {
    const region = persistenceManager.getRegion();
    const token = persistenceManager.getAuthToken();
    if (!region || !token) {
      return;
    }
    if (!shouldConnect) {
      setIsConnected(false);
      setUseRealtimeOrders(false);
      setWebsocket(undefined);
      setLastReceivedTime("");
      setRealtimeConnected(false);
      return;
    }
    const hasRecentlyBeenConnected =
      lastEventSendTime &&
      new Date().getTime() - new Date(lastEventSendTime).getTime() <
        FALLBACK_MAXIMUM_DISCONNECTION_PERIOD;
    // if hasRecentlyBeenConnected try and reconnect regardless of lastOrderPoll success for fallback behaviour
    if (
      (!hasRecentlyBeenConnected && !lastOrderPollSuccess) ||
      (websocket && websocket.readyState !== websocket.CLOSED)
    ) {
      return;
    }
    if (lastConnectionAttempt) {
      const elapsedTime =
        new Date().getTime() - lastConnectionAttempt.getTime();
      if (elapsedTime < RECONNECTION_ATTEMPT_MINIMUM_TIME) {
        setTriggerReconnect(false);
        const timerId = setTimeout(
          () => setTriggerReconnect(true),
          RECONNECTION_ATTEMPT_MINIMUM_TIME - elapsedTime
        );
        return () => clearTimeout(timerId);
      }
    }
    setLastConnectionAttempt(new Date());
    setIsConnected(false);
    const targetUrl = getWebsocketUrl(region);
    const socket = new WebSocket(targetUrl, token);
    socket.onopen = async (event) => {
      setIsConnected(true);
      setLastEventSendTime(new Date().toISOString());
      try {
        const getOrdersResponse = await api.getOrders(token, region as Region);
        if (socket.readyState === socket.OPEN) {
          setOrders(getOrdersResponse.orderDetails);
          if (getOrdersResponse.stateHash !== stateHash) {
            refreshOrderDetails();
          }
          refreshWaitTime();
          setLastEventSendTime(new Date().toISOString());
          setLastReceivedTime(new Date().toISOString());
          setUseRealtimeOrders(true);
          setRealtimeConnected(true);
          setShouldResync(false);
        }
      } catch (err) {
        // This is a fallback for when screens previously had a stable connection but now GET orders is failing
        // Try and maintain the connection
        if (hasRecentlyBeenConnected && socket.readyState === socket.OPEN) {
          console.log("attempting fallback connection");
          refreshWaitTime();
          setLastEventSendTime(new Date().toISOString());
          setLastReceivedTime(new Date().toISOString());
          setUseRealtimeOrders(true);
          setRealtimeConnected(true);
          setShouldResync(true);
        }
      }
    };

    socket.onclose = (event) => {
      setIsConnected(false);
      setUseRealtimeOrders(false);
      setWebsocket(undefined);
      setLastReceivedTime("");
      setRealtimeConnected(false);
    };

    socket.onmessage = async (event) => {
      const parsedMessage = JSON.parse(event.data);
      setLastReceivedTime(new Date().toISOString());
      if (parsedMessage.messageType === "ORDER_UPDATE") {
        const orderUpdateMessage = (parsedMessage as unknown) as UpdateOrderMessage;
        setOrders((prevState) => {
          if (!prevState) {
            return undefined;
          }
          const newOrders = [...prevState];
          const existingOrderIndex = newOrders.findIndex(
            (order) => order.orderId === orderUpdateMessage.order.orderId
          );
          if (existingOrderIndex === -1) {
            newOrders.push(orderUpdateMessage.order);
          } else if (
            newOrders[existingOrderIndex].lastUpdateTime <
            orderUpdateMessage.order.lastUpdateTime
          ) {
            newOrders[existingOrderIndex] = orderUpdateMessage.order;
          }
          return newOrders;
        });
        if (orderUpdateMessage.order.waitTime) {
          refreshWaitTime();
        }
        const updateReceivedMessage = {
          messageType: "ORDER_UPDATE_RECEIVED",
          notificationId: orderUpdateMessage.notificationId,
          timestamp: new Date().toISOString(),
        };
        socket.send(JSON.stringify(updateReceivedMessage));
      } else if (parsedMessage.messageType === "STATE_UPDATE") {
        const stateUpdateMessage = (parsedMessage as unknown) as UpdateStateMessage;
        await refreshOrderDetails();
        const message: StateUpdateReceivedMessage = {
          messageType: "STATE_UPDATE_RECEIVED",
          projectId: stateUpdateMessage.projectId,
          eventTime: stateUpdateMessage.eventTime,
          timestamp: new Date().toISOString(),
        };
        if (stateUpdateMessage.destinationId) {
          message.destinationId = stateUpdateMessage.destinationId;
        }
        socket.send(JSON.stringify(message));
      }
    };
    setWebsocket(socket);
  }, [
    shouldConnect,
    websocket,
    isConnected,
    refreshWaitTime,
    setRealtimeConnected,
    stateHash,
    refreshOrderDetails,
    lastOrderPollSuccess,
    lastConnectionAttempt,
    lastEventSendTime,
    triggerReconnect,
  ]);

  useEffect(() => {
    if (shouldResync) {
      const syncOrderStatus = async () => {
        const region = persistenceManager.getRegion();
        const token = persistenceManager.getAuthToken();
        if (!region || !token) {
          return;
        }
        const getOrdersResponse = await api.getOrders(token, region as Region);
        // This seems overly complex but is required to ensure no updates are lost during period of receiving the orders response
        // and getting a websocket message update
        setOrders((prevOrderState) => {
          if (!prevOrderState) {
            return getOrdersResponse.orderDetails;
          }
          let existingOrders = [...prevOrderState];
          const newOrders = [];
          getOrdersResponse.orderDetails.forEach((order) => {
            const existingIndex = existingOrders.findIndex(
              (existingOrder) => existingOrder.orderId === order.orderId
            );
            if (existingIndex === -1) {
              newOrders.push(order);
            } else {
              const existingOrder = existingOrders[existingIndex];
              if (existingOrder.lastUpdateTime > order.lastUpdateTime) {
                newOrders.push(existingOrder);
              } else {
                newOrders.push(order);
              }
              existingOrders = [
                ...existingOrders.slice(0, existingIndex),
                ...existingOrders.slice(existingIndex + 1),
              ];
            }
          });
          // These are the remaining orders from realtime that were not found in the get orders response (created in between requesting GET orders and receiving response)
          if (existingOrders.length) {
            newOrders.push(...existingOrders);
          }
          return newOrders;
        });
        if (getOrdersResponse.stateHash !== stateHash) {
          refreshOrderDetails();
        }
        refreshWaitTime();
        setShouldResync(false);
      };
      const timerId = setInterval(syncOrderStatus, RESYNC_POLL_INTERVAL);
      return () => clearInterval(timerId);
    }
  }, [shouldResync, stateHash, refreshWaitTime, refreshOrderDetails]);

  useEffect(() => {
    if (isConnected && websocket) {
      const sendConnectionCheckMessage = async () => {
        if (websocket.readyState === websocket.OPEN) {
          const connectionCheckMessage = JSON.stringify({
            messageType: "CONNECTION_CHECK",
            timestamp: new Date().getTime(),
          });
          try {
            websocket.send(connectionCheckMessage);
            setLastEventSendTime(new Date().toISOString());
          } catch (err) {
            console.log("Webhook message error", err);
          }
        } else {
          setIsConnected(false);
          setUseRealtimeOrders(false);
          setWebsocket(undefined);
          setLastReceivedTime("");
          setRealtimeConnected(false);
        }
      };
      const timerId = setTimeout(
        sendConnectionCheckMessage,
        CONNECTION_CHECK_SEND_TIMER
      );
      return () => clearInterval(timerId);
    }
  }, [isConnected, lastEventSendTime, websocket, setRealtimeConnected]);

  useEffect(() => {
    if (isConnected && lastReceivedTime) {
      const expectedLastMessageTime = new Date(
        new Date().getTime() - CONNECTION_CHECK_RECEIVE_TIMER
      ).toISOString();
      if (
        lastReceivedTime < expectedLastMessageTime &&
        websocket?.readyState === websocket?.OPEN
      ) {
        websocket?.close();
        console.log("Closing due to no connection update received");
      }

      setShouldCheckReceivedTime(false);
      const timerId = setTimeout(() => {
        setShouldCheckReceivedTime(true);
      }, CONNECTION_CHECK_RECEIVE_TIMER + 1);
      return () => clearInterval(timerId);
    }
  }, [lastReceivedTime, isConnected, websocket, shouldCheckReceivedTime]);

  return {
    realtimeOrders: orders || [],
    useRealtimeOrders,
    setShouldConnectToRealtimeService: setShouldConnect,
    lastRealtimeEventTime: lastReceivedTime,
  };
};
