import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import useIsOnline from "../../hooks/useIsOnline";

export type NETWORK_STATUS = "ONLINE" | "UNSTABLE" | "OFFLINE" | "REALTIME";

export const NETWORK_STATUS_ENUM: Record<NETWORK_STATUS, NETWORK_STATUS> = {
  ONLINE: "ONLINE",
  UNSTABLE: "UNSTABLE",
  OFFLINE: "OFFLINE",
  REALTIME: "REALTIME",
};

interface ConnectionStatusContextValue {
  networkStatus: NETWORK_STATUS;
  addRequestResult: (isSuccessful: boolean) => void;
  setRealtimeConnected: (isConnected: boolean) => void;
  setRealtimeEnabled: (enabled: boolean) => void;
  lastOrderPollSuccess: boolean;
}

const defaultProviderValue: ConnectionStatusContextValue = {
  networkStatus: "ONLINE",
  addRequestResult: () => console.log("Placeholder addRequestResult called"),
  setRealtimeConnected: () =>
    console.log("Placeholder setRealtimeConnected called"),
  setRealtimeEnabled: () =>
    console.log("Placeholder setRealtimeEnabled called"),
  lastOrderPollSuccess: true,
};

const ConnectionStatusContext = createContext(defaultProviderValue);

const ConnectionStatusProvider = ({ children }: { children: ReactNode }) => {
  const isOnline = useIsOnline();
  const [recentRequestSuccessStatus, setRecentRequestSuccessStatus] = useState<
    boolean[]
  >([]);
  const [networkStatus, setNetworkStatus] = useState(
    window.navigator.onLine
      ? NETWORK_STATUS_ENUM.ONLINE
      : NETWORK_STATUS_ENUM.OFFLINE
  );
  const [realtimeConnected, setRealtimeConnected] = useState(false);
  // TODO: Remove realtimeEnabled logic. This is only a temporary measure to show green indicator when feature is disabled while feature is still being validated in production
  const [realtimeEnabled, setRealtimeEnabled] = useState(false);
  const [lastOrderPollSuccess, setLastOrderPollSuccess] = useState(false);

  useEffect(() => {
    if (!isOnline) {
      setNetworkStatus(NETWORK_STATUS_ENUM.OFFLINE);
      return;
    }
    if (realtimeConnected) {
      setNetworkStatus(NETWORK_STATUS_ENUM.REALTIME);
      return;
    }
    const stableConnection = recentRequestSuccessStatus.reduce(
      (previousValue, currentValue) => previousValue && currentValue,
      true
    );
    if (stableConnection) {
      // TODO: Remove below if statement and replace with setNetworkStatus(NETWORK_STATUS_ENUM.ONLINE);
      if (realtimeEnabled) {
        setNetworkStatus(NETWORK_STATUS_ENUM.ONLINE);
      } else {
        setNetworkStatus(NETWORK_STATUS_ENUM.REALTIME);
      }
    } else {
      setNetworkStatus(NETWORK_STATUS_ENUM.UNSTABLE);
    }
  }, [
    isOnline,
    recentRequestSuccessStatus,
    realtimeConnected,
    realtimeEnabled,
  ]);

  const addRequestResult = useCallback(
    (wasSuccessful: boolean) => {
      setRecentRequestSuccessStatus((prevState) => {
        if (prevState.length < 3) {
          return [wasSuccessful, ...prevState];
        } else {
          return [wasSuccessful, prevState[0], prevState[1]];
        }
      });
      setLastOrderPollSuccess(wasSuccessful);
    },
    [setRecentRequestSuccessStatus]
  );

  const providerValue = {
    networkStatus,
    addRequestResult,
    setRealtimeConnected,
    setRealtimeEnabled,
    lastOrderPollSuccess,
  };

  return (
    <ConnectionStatusContext.Provider value={providerValue}>
      {children}
    </ConnectionStatusContext.Provider>
  );
};

export { ConnectionStatusContext, ConnectionStatusProvider };
